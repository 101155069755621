/**
 * Community management screen
 *
 */

/**
 * React
 */
import * as React from 'react';

/**
 * Redux
 */
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { membersActionCreators } from '../../../controllers/MembersController';
import { invitesActionCreators } from '../Invites';
import { requestsActionCreators } from '../../../controllers/RequestsController';
import { communityActionCreators } from '../../../controllers/CommunityController';
import { authActionCreators } from '../../../controllers/Auth';

/**
 * Child components
 */
import DashboardComponent from '@govteams/library/components/ManageCommunity/DashboardComponent/';

import CommunityActions from '../CommunityActions';
import CommunityEditModal from './../../components/CommunityEditModal';
import InviteMembersModal from '../InviteMembersModal';

/**
 * Constants and utilities
 */
import * as tooltips from '../../../common/Tooltips';
import { ICommunity } from '../../../controllers/CommunityController';
import { getGTApiToken } from '../../../common/auth';
import { isProtected } from '../../../common/Utils';
import CommunityMembers from '../CommunityMembers';
import Members from '../Members';

const CommunityManagementComponent = (props) => {
  const [apiToken, setApiToken] = React.useState<string>(null);
  const [/*isLoading*/, setIsLoading] = React.useState<boolean>(true);
  const [/*authToken*/, setAuthToken] = React.useState<any>(null);
  const [/*screenWidth*/, setScreenWidth] = React.useState<number>(window.innerWidth);

  const {
    teamsToken,
    doShowMembers,
    communityAzureId,
    initialValues,
    pendingInvitations,
    expiredInvitations,
    requestsValues,
    userAzureId,
    // adminMembers,

    authActionCreators,
    membersActionCreators,
    communityActionCreators,
    invitesActionCreators,
    requestsActionCreators,
  } = props;
  const community: ICommunity = initialValues;
  const communityId: number = community?.communityId;

  const onLoad = async () => {
    if (!teamsToken) {
      console.log(`Error: Cannot continue as teams token is empty [${teamsToken}]`);
      return;
    }

    console.log(`Trying to fetch API Token`);
    authActionCreators.storeTeamsToken(teamsToken);
    const _apiToken: string = await getGTApiToken(teamsToken);
    if (_apiToken) {
      setApiToken(_apiToken);
      authActionCreators.storeApiToken(_apiToken);

      setAuthToken({
        gtToken: {
          accessToken: _apiToken,
        },
      });
    }

    if (communityAzureId) {
      await props.communityActionCreators.requestFetchCommunity(communityAzureId, true, _apiToken);
    }
    else {
      console.log(`Error: CommunityID is empty. Cannot proceed [${communityAzureId}]`);
    }

    setIsLoading(false);
  };

  const updateScreenWidth = () => {
    setScreenWidth(window.innerWidth);
  };

  const showMembersList = () => {
    communityActionCreators.showCommunityMembers();
  };

  const hideMembersList = () => {
    communityActionCreators.hideCommunityMembers();
  };

  React.useEffect(() => {
    window.addEventListener('resize', updateScreenWidth);

    return () => {
      window.removeEventListener('resize', updateScreenWidth);
    };
  }, []);

  React.useEffect(() => {
    onLoad().then(null);
  }, [teamsToken, communityAzureId]);

  React.useEffect(() => {
    if (communityId && initialValues) {
      if (initialValues.isOwner) {
        requestsActionCreators.requestFetchRequests(communityId, apiToken);
        invitesActionCreators.requestFetchInvites(communityId, apiToken);
      }
      membersActionCreators.requestFetchMembers(communityId);
    }
  }, [communityId, initialValues]);

  const toggleFavourite = async () => {
    await communityActionCreators.toggleFavourite(community);
  };

  return (
    <>
      <div className='communityManagement-layout'>
        <InviteMembersModal teamsToken={teamsToken}/>
        {/*{this.state.showCommunityMember && (
        <CommunityMembers {...this.props.initialValues} setStateOfCommunityMember={this.setStateOfCommunityMember}/>)}*/}

        {doShowMembers && (
          <CommunityMembers
              communityAzureId={community.communityAzureId}
              handleBackClick={hideMembersList}
          />
        )}

        {!doShowMembers && (
          <DashboardComponent
              community={community}
              constants={{}}
              tooltips={tooltips}
              profile={null}
              govTeamsLogo={null}
              isProtected={isProtected()}
              screenWidth={window.innerWidth}
              toggleFavourite={toggleFavourite}

              requestsProps={{
                values: requestsValues,
                requestChangeRequest: requestsActionCreators?.requestChangeRequest,
              }}
              pendingInvitesProps={{
                invitations: pendingInvitations,
                inviteType: 'pending',
                requestResendInvite: invitesActionCreators?.requestResendInvite,
                requestDeleteInvite: invitesActionCreators?.requestDeleteInvite,
              }}
              expiredInvitesProps={{
                invitations: expiredInvitations,
                inviteType: 'expired',
                requestResendInvite: invitesActionCreators?.requestResendInvite,
                requestDeleteInvite: invitesActionCreators?.requestDeleteInvite,
              }}

              CommunityActionsComponent={<CommunityActions showMembersList={showMembersList}
                                                           userAzureId={userAzureId}/>}
              MembersComponent={<Members
                  {...props}
                  showMembersList={showMembersList}
              />}
              isTeamsApp={true}
          />
        )}
      </div>
      <CommunityEditModal/>
    </>
  );
}
const mapStateToProps = (state) => ({
  doShowMembers: state.community.doShowMembers,
  communityId: state.community.communityId,
  initialValues: state.community.initialValues,
  pendingInvitations: state.invites.pendingInvitations,
  expiredInvitations: state.invites.expiredInvitations,
  requestsValues: state.requests.values,
  adminMembers: state.members.adminMembers,
});

const mapDispatchToProps = (dispatch) => ({
  communityActionCreators: bindActionCreators(communityActionCreators, dispatch),
  authActionCreators: bindActionCreators(authActionCreators, dispatch),
  membersActionCreators: bindActionCreators(membersActionCreators, dispatch),
  invitesActionCreators: bindActionCreators(invitesActionCreators, dispatch),
  requestsActionCreators: bindActionCreators(requestsActionCreators, dispatch)
});

export const CommunityManagement = connect(
    mapStateToProps,
    mapDispatchToProps
)(CommunityManagementComponent);

export default CommunityManagement;
