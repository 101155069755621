/**
 * Community edit modal
 * - Used on community management page
 */

/**
 * React Redux
 */
import * as React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

/**
 * Fabric UI
 */
import { Spinner, SpinnerSize } from '@fluentui/react/lib/Spinner';

/**
 * GovTEAMS component
 */
import CommunityFormCommonComponent from '@govteams/library/components/Community/CommunityFormComponent/';
import * as tooltips from '../../../common/Tooltips';

import { communityActionCreators } from '../../../controllers/CommunityController';
import { authActionCreators } from '../../../controllers/Auth/';
import { isProtected } from '../../../common/Utils';
import config from '../../../config';

const CommunityFormComponent = (props) => {
    const {
        profile,
        communityId,
        auth,
        communityActionCreators,
        initialValues
    } = props;

    return (
        <>
            {!auth?.apiToken && (
                <Spinner size={SpinnerSize.small} title={'Loading...'} />
            )}
            {auth?.apiToken && (
                <CommunityFormCommonComponent
                    isEditForm={true}

                    brandName={tooltips.BRANDNAME}
                    profile={profile}
                    initialValues={initialValues}
                    communityId={communityId}
                    isProtected={isProtected()}

                    isFormDirty={false}
                    setFormIsDirty={null}
                    findSimilarCommunities={null}
                    similarCommunities={null}
                    onSimilarCommunityClick={null}

                    requestFetchCommunity={communityActionCreators.requestFetchCommunity}
                    requestSaveCommunity={communityActionCreators.requestSaveCommunity}
                    endEditCommunity={communityActionCreators.endEditCommunity}

                    tagPickerResolveUrl={`${config.apiBaseUrl}/tags/`}
                    authToken={{
                        gtToken: {
                            accessToken: auth?.apiToken
                        },
                    }}

                    tooltipSponsoredBadge={tooltips.SPONSORED_BADGE}
                />
            )}
        </>
    );
}

const mapStateToProps = (state: any) => ({
    communityId: state.community.communityId,
    communityAzureId: state.community.communityAzureId,

    createError: state.community.error,
    initialValues: state.community.initialValues,
    communityFormState: state.form.CommunityForm,
    profile: state.profile,
    auth: state.auth,
});

const mapDispatchToProps = (dispatch: any) => ({
    communityActionCreators: bindActionCreators(communityActionCreators, dispatch),
    authActionCreators: bindActionCreators(authActionCreators, dispatch),
});

export const CommunityForm = connect(
    mapStateToProps,
    mapDispatchToProps
)(CommunityFormComponent);

export default CommunityForm;
